import axios from '../api.js';
import * as config from '../config.js';

export default {

    // validate user as Admin/TC/Diagnostician
    validateUser: (userEmail) =>
        axios.get(config.baseURL + "validateWebUser/" + userEmail),

    // get summary report data
    getemdedreportURL: (req, language) =>
        axios.get(config.baseURL + 'getEmbedConfigInfo/' + req + "/" + language),

    // get usage report data
    getUsageEmbedInfo: () =>
        axios.get(config.baseURL + 'getUsageEmbedInfo')
}
