import React from "react";


function NotAuthorized() {
    return (
        <div style={{ width: '50%', margin: '0 auto', marginTop: '15%', textAlign: 'center' }}>
            <div class="card">
                <div class="card-body">
                    <div class="mb-2">
                        <img src="" alt="" />
                    </div>
                    You are Not Authorised to access this page.
                </div>
            </div>
        </div>)
}
export default NotAuthorized;