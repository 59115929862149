import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import postingService from '../../services/postingService.js';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import moment from "moment";
import LoadingOverlay from "react-loading-overlay";
import { authContext } from "../../adalConfig";
import userservice from '../../services/userService.js';
import { subMonths } from "date-fns";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NoDataIndication = () => (
  <div className="spinner">
    <div className="text-center">No Records Found</div>
    <div className="rect1" />
    <div className="rect2" />
    <div className="rect3" />
    <div className="rect4" />
    <div className="rect5" />
  </div>
);

let options = {
  showTotal: true,
  sizePerPageList: [{
    text: '25', value: 25
  }, {
    text: '50', value: 50
  }, {
    text: '75', value: 75
  }]
};

export default class Posting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateResultCompleted: null,
      dateResultCompleted1: null,
      isLoading: true,
      affiliatesList: [],
      customerList: [],
      customerListAll: [],
      gridData: [],
      isAdminUSer: false,
      userAffiliates: [],
      userirn: '',
      data: [],
      setSelectedCountry: "",
      setCities: "",
      setSelectedCity: "",
      selectedCounty: ""

    };

    const userEmail = authContext.getCachedUser() !== null ? authContext.getCachedUser().userName.toLocaleLowerCase() : '';
    // validate user as Admin/TC/Diagnostician
    userservice.validateUser(userEmail).then((userResponse) => {
      // handle success
      let adminUser = userResponse.data.isAdminUser;
      let userAffiliates = userResponse.data.affiliatesList.sort(this.sortIt('affiliatename'));
      let uirn = userResponse.data.userirn;
      this.setState({ userirn: userResponse.data.userirn });
      this.setState({ isAdminUSer: userResponse.data.isAdminUser });
      this.setState({ userAffiliates: userResponse.data.affiliatesList.sort(this.sortIt('affiliatename')) });

      let req = { issearch: false, urin: uirn, isAdmin: adminUser, affiliate: 'All', customer: 'All', startdate: null, enddate: null };

      let todate = new Date();
      let fromdate = new Date();
      fromdate.setMonth(todate.getMonth() - 24);
      req.startdate = moment(fromdate).format("DD/MM/YYYY");
      req.enddate = moment(todate).format("DD/MM/YYYY");
      req.issearch = true;

      // Display table data 
      postingService.getPostingSessionsForLoggedInUser(req)
        .then((response) => {
          // handle success
          this.setState({ data: response.data });
          this.setState({ gridData: response.data });
          this.setState({ isLoading: false });
        })
        .catch((error) => {
          //handle error
          this.setState({ isLoading: false });
        });

      // get affiliates list
      postingService.getAllAffiliates()
        .then((response) => {
          // handle success
          let affiliates = response.data;
          if (adminUser) {
            this.setState({ affiliatesList: affiliates.sort(this.sortIt('affiliatename')) });
          }
          else {
            if (userAffiliates && userAffiliates.length > 0) {
              let userAffiliatesLst = [];
              userAffiliates.forEach(element => {
                let aff = affiliates.filter(x => x.irn === element.affliate);
                userAffiliatesLst.push.apply(userAffiliatesLst, aff);
              });
              this.setState({ affiliatesList: userAffiliatesLst.sort(this.sortIt('affiliatename')) });
            } else {
              this.setState({ affiliatesList: [] });
            }
          }
        })
        .catch((error) => {
          //handle error
        });

      // get customers list
      postingService.getAllCustomers()
        .then((response) => {
          // handle success
          let customers = response.data;

          if (adminUser) {
            this.setState({ customerList: customers.sort(this.sortIt('customername')) });
            this.setState({ customerListAll: customers.sort(this.sortIt('customername')) });
          }
          else {
            if (userAffiliates && userAffiliates.length > 0) {
              let usrcustomersLst = [];

              userAffiliates.forEach(element => {
                let cus = customers.filter(x => x.affiliatesirn === element.affliate);
                usrcustomersLst.push.apply(usrcustomersLst, cus);
              });

              this.setState({ customerList: usrcustomersLst.sort(this.sortIt('customername')) });
              this.setState({ customerListAll: usrcustomersLst.sort(this.sortIt('customername')) });

            } else {
              this.setState({ customerList: [] });
              this.setState({ customerListAll: [] });
            }
          }

        })
        .catch((error) => {
          //handle error
        });
    }).catch((error) => {
      //handle error
    });
  }

  // define table columns
  columns = [
    {
      dataField: 'affiliatename',
      text: 'Affiliate',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      }
    }, {
      dataField: 'customername',
      text: 'Customer Name',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      }
    },
    {
      dataField: 'customerlocationname',
      text: 'Complex',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      }
    },
    {
      dataField: 'postingdate',
      text: 'Necropsy Date',
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div>
            {moment(row.postingdate).format("DD/MM/YYYY")}
          </div>
        )
      },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      }
    },
    {
      dataField: 'creationdate',
      text: 'Submitted Date',
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div>
            {moment(row.creationdate).format("DD/MM/YYYY")}
          </div>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      }
    },
    {
      dataField: 'displayname',
      text: 'Submitted by',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      }
    },
    {
      dataField: "",
      text: 'Actions',
      formatter: (cellContent, row) => {
        return (
          <a target="_blank" rel="noreferrer" href={"/ViewPosting?irn=" + row.irn}>View</a>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "4%" };
      }
    }
  ];

  // change the customer list based on affiliate selected
  handleCountrySelect(e) {
    const selectedAffiliate = e.target.value;
    if (selectedAffiliate === 'All') {
      this.setState({ customerList: this.state.customerListAll.sort(this.sortIt('customername')) })
    }
    else {
      let customers = this.state.customerListAll.filter(x => x.affiliatesirn === selectedAffiliate)
      this.setState({ customerList: customers.sort(this.sortIt('customername')) })
    }
  }

  // search data
  globalSearch = (event) => {
    if (event !== '') {
      document.getElementById('txtSearchInput').classList.remove('my-search');
      document.getElementById("searchclear").style.display = "block";
    } else {
      document.getElementById('txtSearchInput').classList.add('my-search');
      document.getElementById("searchclear").style.display = "none";
    }
    let searchInput = event;
    let searchData = this.state.data.filter(value => {
      return ( // eslint-disable-next-line
        !!value.affiliatename && value.affiliatename.toLowerCase().includes(searchInput.toLowerCase()) ||
        // eslint-disable-next-line
        !!value.customername && value.customername.toLowerCase().includes(searchInput.toLowerCase()) ||
        // eslint-disable-next-line
        !!value.customerlocationname && value.customerlocationname.toLowerCase().includes(searchInput.toLowerCase()) ||
        // eslint-disable-next-line
        !!value.postingdate &&
        // eslint-disable-next-line
        this.filterByDate(value.postingdate, searchInput.toLowerCase()) ||
        // eslint-disable-next-line
        !!value.creationdate &&
        // eslint-disable-next-line
        this.filterByDate(value.creationdate, searchInput.toLowerCase()) ||
        // eslint-disable-next-line
        !!value.displayname && value.displayname.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ gridData: searchData })
  };

  // date format
  filterByDate = (inputDate, filter) => {
    const m = moment(inputDate).format("DD/MM/YYYY");
    return m.toLocaleLowerCase().includes(filter);
  };

  // keydown event listener
  keyDownEvent = (event) => {
    const key = event.key;
    if (key === "Backspace" || key === "Delete") {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  };

  // used to set a new state for the input
  handleChangeValue = (e, type, index) => {
    if (e !== undefined && e !== null && type === "date") {
      const name = index;
      this.setState({ [name]: e });
      if (index === "dateResultCompleted1") {
        var dateOne1 = new Date(e);
        var dateTwo1 = new Date(this.state.dateResultCompleted);
        if (dateOne1 < dateTwo1) {
          this.setState({ dateResultCompleted1: null });
          toast.error("To date should be greater than or equal to From date");
        }
      }

    } else {
      const name = e !== undefined ? e.target.name : "";
      const value = e !== undefined ? e.target.value : "";
      this.setState({ [name]: value });
    }
  };

  // filter search
  filterGo() {
    const req = {};
    req.issearch = true;
    req.urin = this.state.userirn;
    req.isAdmin = this.state.isAdminUSer;
    req.affiliate = document.getElementById('affiliatesirn').value;
    req.customer = document.getElementById('customerddl').value;
    req.startdate = null;
    req.enddate = null;
    let startDate = document.getElementById('dpStartDate').value;
    let endDate = document.getElementById('dpEndDate').value;

    if (startDate !== null && startDate !== undefined && startDate !== '') {
      req.startdate = startDate;
    }
    if (endDate !== null && endDate !== undefined && endDate !== '') {
      req.enddate = endDate;
    }

    if (req.startdate === null && req.enddate === null) {
      req.issearch = true;
    }
    if (req.enddate === null) {
      let fromdate = new Date();
      req.enddate = moment(fromdate).format("DD/MM/YYYY");
    }
    if (req.startdate === null) {
      let todate = new Date();
      let fromdate = new Date();
      fromdate.setMonth(todate.getMonth() - 24);
      req.startdate = moment(fromdate).format("DD/MM/YYYY");
    }

    this.setState({ isLoading: true });
    // display grid data after search 
    postingService.getPostingSessionsForLoggedInUser(req)
      .then((response) => {
        // handle success
        this.setState({ data: response.data });
        this.setState({ gridData: response.data });
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        // handle error
        this.setState({ isLoading: false });
      });
  }

  // date picker
  openDatepickerStart = () => this._startCalendar.setOpen(true);
  openDatepickerEnd = () => this._endCalendar.setOpen(true);

  // clear search data
  showHideSearchClear() {
    document.getElementById("searchclear").style.display = "none";
    document.getElementById("txtSearchInput").classList.add("my-search");
    document.getElementById("txtSearchInput").value = "";
    this.globalSearch("");
  }

  // go to page
  goToHome = () => {
    window.location.href = "/"
  };

  // sort data
  sortIt = sortBy => (a, b) => {
    if (a[sortBy] > b[sortBy]) {
      return 1;
    } else if (a[sortBy] < b[sortBy]) {
      return -1;
    }
    return 0;
  }

  render() {
    return (
      <LoadingOverlay active={this.state.isLoading} spinner>
        <div className="row">
          <div className="col-md-12 form-row pt-2">
            <div className="ml-4 pl-2 pr-2 mr-4 w-100">

              <div className="tab-content">

                <div
                  role="tabpanel"
                  className="tab-pane show active"
                  id="tabOne"
                >
                  <div className="pl-2">
                    <div className="row mt-2">
                      <form className="form-inline" action="">
                        <label htmlFor="email" className="mr-sm-2 mb-2">
                          Affiliate:
                        </label>
                        <select
                          className="form-control custom-form-control mb-2 mr-sm-4"
                          id="affiliatesirn"
                          onChange={e => this.handleCountrySelect(e)}

                        >
                          <option value="All">All</option>
                          {this.state.affiliatesList.map((item) => (
                            <option key={item.affiliatename} value={item.irn}>
                              {item.affiliatename}
                            </option>
                          ))}
                        </select>

                        <label htmlFor="email" className="mr-sm-2 mb-2">
                          Customer:
                        </label>
                        <select
                          className="form-control custom-form-control mb-2 mr-sm-4"
                          id="customerddl"


                        >
                          <option value="All">All</option>
                          {this.state.customerList.map((item) => (
                            <option key={item.customername} value={item.irn}>
                              {item.customername}
                            </option>
                          ))}
                        </select>

                        <label htmlFor="email" className="mr-sm-2 mb-2">
                          From Date:
                        </label>
                        <DatePicker
                          className="form-control custom-form-control my-icon mb-2 mr-sm-4"
                          placeholderText="DD/MM/YYYY"
                          selected={this.state.dateResultCompleted}
                          onChange={(e) =>
                            this.handleChangeValue(
                              e,
                              "date",
                              "dateResultCompleted"
                            )
                          }
                          autoComplete="off"
                          onKeyDown={(e) => {
                            this.keyDownEvent(e);
                          }}
                          dateFormat="dd/MM/yyyy"
                          maxDate={moment().toDate()}
                          minDate={subMonths(new Date(), 24)}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          ref={(c) => (this._startCalendar = c)}
                          id="dpStartDate"
                        />

                        <label htmlFor="email" className="mb-2 mr-sm-2">
                          To Date:
                        </label>
                        <DatePicker
                          className="form-control custom-form-control my-icon mb-2 mr-sm-4"
                          placeholderText="DD/MM/YYYY"
                          selected={this.state.dateResultCompleted1}
                          onChange={(e) =>
                            this.handleChangeValue(
                              e,
                              "date",
                              "dateResultCompleted1"
                            )
                          }
                          autoComplete="off"
                          onKeyDown={(e) => {
                            this.keyDownEvent(e);
                          }}

                          dateFormat="dd/MM/yyyy"
                          maxDate={moment().toDate()}
                          minDate={subMonths(new Date(), 24)}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          ref={(c) => (this._endCalendar = c)}
                          id="dpEndDate"
                        />
                        <button
                          className="btn btn-primary btn-sm mb-2 mr-sm-4"
                          type="button"
                          id="filterGoButton"
                          onClick={() => this.filterGo()}
                        >
                          Go
                        </button>
                        <button
                          className="btn btn-primary btn-sm mb-2 mr-sm-4"
                          type="button"
                          id="resetButton"
                          onClick={() => this.goToHome()}
                        >
                          Reset
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="col-md-12">
          <div className="panel-bg  mt-2">
            {" "}

            <div className="panelTitle d-flex">
              <div className="col-6 pt-1">
                <strong></strong>
              </div>
              <div className="col-6 pt-1 text-left">

              </div>
              <div className="col-6 text-right">

              </div>
            </div>
            <div className="p-3 mb70" style={{ marginBottom: "250px" }}>
              {/* <!--  Table Panel Start --> */}
              <ToolkitProvider
                keyField="ReportId"
                data={this.state.gridData}
                columns={this.columns}

              >
                {(props) => (
                  <div>
                    <div className="text-right search-position">
                      <input type="text" className="form-control search-input my-search pr28" placeholder="Search" id="txtSearchInput" onInput={e => this.globalSearch(e.target.value)} />
                      <span
                        id="searchclear"
                        onClick={() => this.showHideSearchClear()}
                        style={{ display: "none" }}
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </span>
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      pagination={paginationFactory({ ...options })}

                      noDataIndication={() => <NoDataIndication />}
                    />
                  </div>
                )}
              </ToolkitProvider>
              {/* table panel ends */}
            </div>
          </div>
        </div>

      </LoadingOverlay>

    );
  }
}