import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import postingService from '../../services/postingService.js';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import moment from "moment";
import LoadingOverlay from "react-loading-overlay";
import { authContext } from "../../adalConfig";
import userservice from '../../services/userService.js';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const NoDataIndication = () => (
  <div className="spinner">
    <div className="text-center">No Records Found</div>
    <div className="rect1" />
    <div className="rect2" />
    <div className="rect3" />
    <div className="rect4" />
    <div className="rect5" />
  </div>
);
let options = {
  showTotal: true,
  sizePerPageList: [{
    text: '25', value: 25
  }, {
    text: '50', value: 50
  }, {
    text: '75', value: 75
  }]
};

export default class detailedReportRequests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateResultCompleted: null,
      dateResultCompleted1: null,
      isLoading: true,
      affiliatesList: [],
      customerList: [],
      customerListAll: [],
      gridData: [],
      isAdminUSer: false,
      userAffiliates: [],
      userirn: '',
      data: [],
      setSelectedCountry: "",
      setCities: "",
      setSelectedCity: "",
      selectedCounty: "",
      dataflagupdate: []
    };

    const userEmail = authContext.getCachedUser() !== null ? authContext.getCachedUser().userName.toLocaleLowerCase() : '';
    // validate user as Admin/TC/Diagnostician
    userservice.validateUser(userEmail).then((userResponse) => {
      // handle success
      let adminUser = userResponse.data.isAdminUser;
      let userAffiliates = userResponse.data.affiliatesList.sort(this.sortIt('affiliatename'));
      let uirn = userResponse.data.userirn;

      this.setState({ userirn: userResponse.data.userirn });
      this.setState({ isAdminUSer: userResponse.data.isAdminUser });
      this.setState({ userAffiliates: userResponse.data.affiliatesList.sort(this.sortIt('affiliatename')) });

      let req = { issearch: false, urin: uirn, isAdmin: adminUser, affiliate: 'All', customer: 'All', status: 'All', startdate: null, enddate: null };

      // Display table data without filters 
      postingService.getReportRequestsForLoggedInUser(req)
        .then((response) => {
          // handle success
          this.setState({ data: response.data });
          this.setState({ gridData: response.data });
          this.setState({ isLoading: false });
        })
        .catch((error) => {
          //handle error
          this.setState({ isLoading: false });
        });

      // get affiliates list
      postingService.getAllAffiliates()
        .then((response) => {
          // handle success
          let affiliates = response.data;
          this.setState({ isLoading: false });
          if (adminUser) {
            this.setState({ affiliatesList: affiliates.sort(this.sortIt('affiliatename')) });
          }
          else {
            if (userAffiliates && userAffiliates.length > 0) {
              let userAffiliatesLst = [];

              userAffiliates.forEach(element => {
                let aff = affiliates.filter(x => x.irn === element.affliate);
                userAffiliatesLst.push.apply(userAffiliatesLst, aff);
              });

              this.setState({ affiliatesList: userAffiliatesLst.sort(this.sortIt('affiliatename')) });
            } else {
              this.setState({ affiliatesList: [] });
            }
          }
          this.setState({ isLoading: false });
        })
        .catch((error) => {
          //handle error
          this.setState({ isLoading: false });
        });

      // get customers list  
      postingService.getAllCustomers()
        .then((response) => {
          // handle success
          let customers = response.data;
          if (adminUser) {
            this.setState({ customerList: customers.sort(this.sortIt('customername')) });
            this.setState({ customerListAll: customers.sort(this.sortIt('customername')) });
          }
          else {
            if (userAffiliates && userAffiliates.length > 0) {
              let usrcustomersLst = [];
              userAffiliates.forEach(element => {
                let cus = customers.filter(x => x.affiliatesirn === element.affliate);
                usrcustomersLst.push.apply(usrcustomersLst, cus);
              });

              this.setState({ customerList: usrcustomersLst.sort(this.sortIt('customername')) });
              this.setState({ customerListAll: usrcustomersLst.sort(this.sortIt('customername')) });

            } else {
              this.setState({ customerList: [] });
              this.setState({ customerListAll: [] });

            }
          }
          this.setState({ isLoading: false });
        })
        .catch((error) => {
          //handle error
          this.setState({ isLoading: false });
        });

    }).catch((error) => {
      //handle error
    });
  }

  // define table columns
  columns = [
    {
      dataField: 'affiliatename',
      text: 'Affiliate',
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <a target="_blank" rel="noreferrer" href={"/ViewPosting?irn=" + row.irn}>{row.affiliatename}</a>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      }
    },
    {
      dataField: 'customername',
      text: 'Customer Name',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "14%" };
      }
    },
    {
      dataField: 'customerlocationname',
      text: 'Complex',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "14%" };
      }
    },
    {
      dataField: 'displayname',
      text: 'Submitted by',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      }
    },

    {
      dataField: 'requesteddate',
      text: 'Requested Date',
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div>
            {moment(row.requesteddate).format("DD/MM/YYYY")}
          </div>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      }
    },
    {
      dataField: 'reportstatus',
      text: 'Status',
      sort: true,

      formatter: (cellContent, row) => {
        if (row.reportstatus === 'Completed') {
          return (
            <div style={{ color: 'green' }}>Completed</div>
          )
        }
        else {
          return (
            <div>Pending</div>
          )
        }
      },

      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      }
    },
    {
      dataField: "",
      text: 'Action',
      align: 'center',
      formatter: (cellContent, row) => {
        if (row.reportstatus === 'Pending') {
          return (
            <button type="button" onClick={e => { this.updateFlag(row.irn) }} className="btn btn-outline-primary"> &nbsp;Mark as complete</button>
          )
        }
      },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      }
    }
  ];

  // update report request
  updateFlag = (id1) => {
    let obj = { irn: id1 }
    postingService.updateReportRequest(obj).then((response) => {
      // handle success      
      this.setState({ dataflagupdate: response.data })
      this.filterGo1();
      toast.success('Updated successfully')
    }).catch((error) => {
      // handle error
      this.setState({ isLoading: false });
    })
    this.setState({ dataflagupdate: this.state.dataflagupdate })
  }

  // show customer list based on the selection of affiliate 
  handleCountrySelectdetail(e) {
    const selectedAffiliate = e.target.value;
    if (selectedAffiliate === 'All') {
      this.setState({ customerList: this.state.customerListAll.sort(this.sortIt('customername')) })
    }
    else {
      let customers = this.state.customerListAll.filter(x => x.affiliatesirn === selectedAffiliate)
      this.setState({ customerList: customers.sort(this.sortIt('customername')) })
    }
  }

  // search data
  globalSearchdetail = (event) => {
    if (event !== '') {
      document.getElementById('txtSearchInputdetail').classList.remove('my-search');
      document.getElementById("searchcleardetail").style.display = "block";
    } else {
      document.getElementById('txtSearchInputdetail').classList.add('my-search');
      document.getElementById("searchcleardetail").style.display = "none";
    }
    let searchInput1 = event;
    let searchData = this.state.data.filter(value => {
      return ( // eslint-disable-next-line
        !!value.affiliatename && value.affiliatename.toLowerCase().includes(searchInput1.toLowerCase()) ||
        // eslint-disable-next-line
        !!value.customername && value.customername.toLowerCase().includes(searchInput1.toLowerCase()) ||
        // eslint-disable-next-line
        !!value.customerlocationname && value.customerlocationname.toLowerCase().includes(searchInput1.toLowerCase()) ||
        // eslint-disable-next-line
        !!value.requesteddate &&
        // eslint-disable-next-line
        this.filterByDate1(value.requesteddate, searchInput1.toLowerCase()) ||
        // eslint-disable-next-line
        !!value.reportstatus && value.reportstatus.toLowerCase().includes(searchInput1.toLowerCase()) ||
        // eslint-disable-next-line
        !!value.displayname && value.displayname.toLowerCase().includes(searchInput1.toLowerCase())
      );
    });
    this.setState({ gridData: searchData })
  };

  // Requested Date format 
  filterByDate1 = (inputDate, filter) => {
    const m = moment(inputDate).format("DD/MM/YYYY");
    return m.toLocaleLowerCase().includes(filter);
  };

  // keydown event listener
  keyDownEvent = (event) => {
    const key = event.key;
    if (key === "Backspace" || key === "Delete") {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  };

  // used to set a new state for the input
  handleChangeValue = (e, type, index) => {
    if (e !== undefined && type === "date") {
      const name = index;
      this.setState({ [name]: e });
    } else {
      const name = e !== undefined ? e.target.name : "";
      const value = e !== undefined ? e.target.value : "";
      this.setState({ [name]: value });
    }
  };

  // filter search
  filterGo1() {
    const req = {};
    req.issearch = true;
    req.urin = this.state.userirn;
    req.isAdmin = this.state.isAdminUSer;
    req.affiliate = document.getElementById('affiliatesirndetail1').value;
    req.customer = document.getElementById('customerddldetail').value;
    req.status = document.getElementById('status').value;
    req.startdate = null;
    req.enddate = null;
    let startDate = document.getElementById('dpStartDateDetail').value;
    let endDate = document.getElementById('dpEndDateDetail').value;

    if (startDate !== null && startDate !== undefined && startDate !== '') {
      req.startdate = startDate;
    }
    if (endDate !== null && endDate !== undefined && endDate !== '') {
      req.enddate = endDate;
    }
    if (req.affiliate === 'All' && req.customer === 'All' && req.status === 'All' && req.startdate === null && req.enddate === null) {
      req.issearch = false;
    }
    this.setState({ isLoading: true });
    // filter table data
    postingService.getReportRequestsForLoggedInUser(req)
      .then((response) => {
        // handle success
        this.setState({ data: response.data });
        this.setState({ gridData: response.data });
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        // handle error
        this.setState({ isLoading: false });
      });


  }

  // Date picker
  openDatepickerStart = () => this._startCalendar.setOpen(true);
  openDatepickerEnd = () => this._endCalendar.setOpen(true);

  // clear the search data
  showHideSearchCleardetail() {
    document.getElementById("searchcleardetail").style.display = "none";
    document.getElementById("txtSearchInputdetail").classList.add("my-search");
    document.getElementById("txtSearchInputdetail").value = "";
    this.globalSearchdetail("");
  }

  // refresh the page
  goToHome = () => {
    document.getElementById('affiliatesirndetail1').value = 'All';
    document.getElementById('customerddldetail').value = 'All';
    document.getElementById('status').value = 'All';
    this.setState({ dateResultCompleted: null });
    this.setState({ dateResultCompleted1: null });
    let req = { issearch: false, urin: this.state.userirn, isAdmin: this.state.isAdminUSer, affiliate: 'All', customer: 'All', status: 'All', startdate: null, enddate: null };
    this.setState({ isLoading: true });
    postingService.getReportRequestsForLoggedInUser(req)
      .then((response) => {
        // handle success
        this.setState({ data: response.data });
        this.setState({ gridData: response.data });
        this.setState({ customerList: this.state.customerListAll.sort(this.sortIt('customername')) })
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        // handle error
        this.setState({ isLoading: false });
      });
  };
  // sort data
  sortIt = sortBy => (a, b) => {
    if (a[sortBy] > b[sortBy]) {
      return 1;
    } else if (a[sortBy] < b[sortBy]) {
      return -1;
    }
    return 0;
  }
  render() {
    return (

      <LoadingOverlay active={this.state.isLoading} spinner>
        <div className="row">
          <div className="col-md-12 form-row pt-2">
            <div className="ml-4 pl-2 pr-2 mr-4 w-100">

              <div className="tab-content">

                <div
                  role="tabpanel"
                  className="tab-pane show active"
                  id="tabOne"
                >
                  <div className="pl-2">
                    <div className="row mt-2">
                      <form className="form-inline" action="">
                        <label htmlFor="email" className="mr-sm-2 mb-2">
                          Affiliate:
                        </label>
                        <select
                          className="form-control custom-form-control mb-2 mr-sm-4"
                          id="affiliatesirndetail1"
                          onChange={e => this.handleCountrySelectdetail(e)}

                        >
                          <option value="All">All</option>
                          {this.state.affiliatesList.map((item) => (
                            <option key={item.affiliatename} value={item.irn}>
                              {item.affiliatename}
                            </option>
                          ))}
                        </select>

                        <label htmlFor="email" className="mr-sm-2 mb-2">
                          Customer:
                        </label>
                        <select
                          className="form-control custom-form-control mb-2 mr-sm-4"
                          id="customerddldetail">
                          <option value="All">All</option>
                          {this.state.customerList.map((item) => (
                            <option key={item.customername} value={item.irn}>
                              {item.customername}
                            </option>
                          ))}
                        </select>
                        <label htmlFor="email" className="mr-sm-2 mb-2">
                          Status:
                        </label>
                        <select
                          className="form-control custom-form-control mb-2 mr-sm-4"
                          id="status">
                          <option value="All">All</option>
                          <option value="0">Completed</option>
                          <option value="1">Pending</option>
                        </select>
                        <label htmlFor="email" className="mr-sm-2 mb-2">
                          From Date:
                        </label>
                        <DatePicker
                          className="form-control custom-form-control my-icon mb-2 mr-sm-4"
                          placeholderText="DD/MM/YYYY"
                          selected={this.state.dateResultCompleted}
                          onChange={(e) =>
                            this.handleChangeValue(
                              e,
                              "date",
                              "dateResultCompleted"
                            )
                          }
                          autoComplete="off"
                          onKeyDown={(e) => {
                            this.keyDownEvent(e);
                          }}
                          dateFormat="dd/MM/yyyy"
                          ref={(c) => (this._startCalendar = c)}
                          id="dpStartDateDetail"
                        />

                        <label htmlFor="email" className="mb-2 mr-sm-2">
                          To Date:
                        </label>
                        <DatePicker
                          className="form-control custom-form-control my-icon mb-2 mr-sm-4"
                          placeholderText="DD/MM/YYYY"
                          selected={this.state.dateResultCompleted1}
                          onChange={(e) =>
                            this.handleChangeValue(
                              e,
                              "date",
                              "dateResultCompleted1"
                            )
                          }
                          autoComplete="off"
                          onKeyDown={(e) => {
                            this.keyDownEvent(e);
                          }}
                          dateFormat="dd/MM/yyyy"
                          ref={(c) => (this._endCalendar = c)}
                          id="dpEndDateDetail"
                        />
                        <button
                          className="btn btn-primary btn-sm mb-2 mr-sm-4"
                          type="button"
                          onClick={() => this.filterGo1()}
                        >
                          Go
                        </button>
                        <button
                          className="btn btn-primary btn-sm mb-2 mr-sm-4"
                          type="button"
                          id='ResetButton'
                          onClick={() => this.goToHome()}
                        >
                          Reset
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="col-md-12">
          <div className="panel-bg  mt-2">
            {" "}

            <div className="panelTitle d-flex">
              <div className="col-6 pt-1">
                <strong></strong>
              </div>
              <div className="col-6 pt-1 text-left">

              </div>
              <div className="col-6 text-right">

              </div>
            </div>
            <div className="p-3 mb70" style={{ marginBottom: "250px" }}>
              {/* <!--  Table Panel Start --> */}
              <ToolkitProvider
                keyField="ReportId"
                data={this.state.gridData}
                columns={this.columns}

              >
                {(props) => (
                  <div>
                    <div className="text-right search-position">
                      <input type="text" className="form-control search-input my-search pr28" placeholder="Search" id="txtSearchInputdetail" onInput={e => this.globalSearchdetail(e.target.value)} />
                      <span
                        id="searchcleardetail"
                        onClick={() => this.showHideSearchCleardetail()}
                        style={{ display: "none" }}
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </span>
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      defaultSortDirection="asc"
                      pagination={paginationFactory({ ...options })}
                      noDataIndication={() => <NoDataIndication />}
                    />
                  </div>
                )}
              </ToolkitProvider>
              {/* table panel ends */}
            </div>
          </div>
        </div>

      </LoadingOverlay>

    );
  }
}