import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Modal } from 'react-bootstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import postingService from '../../services/postingService.js';
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from 'react-select';
import commonService from '../../services/commonService.js';

const NoDataIndication = () => (
    <div className="spinner">
        <div className="text-center">No Records Found</div>
        <div className="rect1" />
        <div className="rect2" />
        <div className="rect3" />
        <div className="rect4" />
        <div className="rect5" />
    </div>
);

let options = {
    showTotal: true,
    sizePerPageList: [{
        text: '25', value: 25
    }, {
        text: '50', value: 50
    }, {
        text: '75', value: 75
    }]
};

export default class Language extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dateResultCompleted: null,
            dateResultCompleted1: null,
            isLoading: true,
            affiliatesList: [],
            customerList: [],
            customerListAll: [],
            gridData: [],
            isAdminUSer: false,
            userAffiliates: [],
            userirn: '',
            data: [],
            emaildata: [],
            firstname: '',
            emailiddata: '',
            usersirndata: '',
            customernamedata: '',
            setSelectedCountry: "",
            setCities: "",
            setSelectedCity: "",
            selectedCounty: "",
            showHide4: false,
            showHide2: false,
            showHide: false,
            email: "",
            isHidden: true,
            deletedata: [],
            deleteirn: '',
            activeflag: '',
            selectedcustomerirn: null,
            selectedcustomeraffiliateirn: null,
            value: '',
            userid: '',
            useriddata: '',
            selectedcustomerOption: [],
            customerirndata: [],
            affiliateirndata: []
        };
        let datauser = commonService.getUserlogin();
        this.setState({ userid: datauser.userid });




        // get getLanguageData
        postingService.getLanguageData()
            .then((response) => {
                //handle success
                this.setState({ data: response.data });
                this.setState({ isLoading: false });
            })
            .catch((error) => {
                //handle error
                this.setState({ isLoading: false });
            });
    }

    // define table columns
    columns = [

        {
            dataField: 'languagename', text: 'Language', sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "18%" };
            }
        },
        {
            dataField: "",
            text: 'Actions',
            align: 'center',
            headerAlign: (column, colIndex) => 'center',
            formatter: (cellContent, row) => {
                if (row.languagename === "English") {
                    return (
                        <button type="button" disabled="true" className="btn btn-outline-danger">Deactivate</button>
                    )
                }
                if (row.isactive === '1') {
                    return (
                        <button type="button" className="btn btn-outline-danger" onClick={() => this.handleModal1ShowHide(row.irn, row.isactive)}>Deactivate</button>
                    )
                }
                else {
                    return (
                        <button type="button" className="btn btn-outline-success" onClick={() => this.handleModalShowHide(row.irn, row.isactive)}>&nbsp;&nbsp;Activate&nbsp;&nbsp;</button>
                    )
                }
            },
            headerStyle: (colum, colIndex) => {
                return { width: "7%" };
            }
        }
    ];

    // sort data
    sortIt = sortBy => (a, b) => {
        if (a[sortBy] > b[sortBy]) {
            return -1;
        } else if (a[sortBy] < b[sortBy]) {
            return 1;
        }
        return 0;
    }



    // Deactivate language model popup
    handleModal1ShowHide(irn, isactive) {
        this.setState({ showHide2: !this.state.showHide2 });
        this.setState({ deleteirn: irn });
        this.setState({ activeflag: isactive });
    }

    // Activate language model popup
    handleModalShowHide(irn, isactive) {
        this.setState({ showHide: !this.state.showHide });
        this.setState({ deleteirn: irn });
        this.setState({ activeflag: isactive });
    }





    // used to set a new state for the input
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    // Deactivate the user
    removeData() {
        let req = {};
        req.irn = this.state.deleteirn
        req.isactive = '0'
        postingService.activateordeactivatelang(req).then((response) => {
            // handle success    
            this.setState({ showHide2: !this.state.showHide2 })
            this.setState({ isLoading: false });
            this.setState({ deletedata: response.data })
            toast.success("Deactivated Successfully");
            this.setState({ isHidden: true })
            // table data 
            postingService.getLanguageData()
                .then((response) => {
                    this.setState({ data: response.data });
                })
                .catch((error) => {
                    this.setState({ isLoading: false });
                });
        }).catch((error) => {
            // handle error
            this.setState({ isLoading: false });
            toast.error("Not Deactivated Successfully");
        });
        this.setState({ deletedata: this.state.deletedata })
        this.setState({ email: '' })
    }

    // activate the user
    removeData1() {
        let req = {};
        req.irn = this.state.deleteirn
        req.isactive = '1'
        postingService.activateordeactivatelang(req).then((response) => {
            // handle success    
            this.setState({ showHide: !this.state.showHide })
            this.setState({ isLoading: false });
            this.setState({ deletedata: response.data })
            toast.success("Activated Successfully");
            this.setState({ isHidden: true })
            // table data 
            postingService.getLanguageData()
                .then((response) => {
                    // handle success
                    this.setState({ data: response.data });
                })
                .catch((error) => {
                    // handle error
                    this.setState({ isLoading: false });
                });
        }).catch((error) => {
            // handle error
            this.setState({ isLoading: false });
            toast.error("Not Deactivated Successfully");
        });
        this.setState({ deletedata: this.state.deletedata })
        this.setState({ email: '' })
    }

    render() {
        return (

            <LoadingOverlay active={this.state.isLoading} spinner >
                <div style={{ marginTop: "20px" }} ></div>
                <div className="mt-4">
                    {this.state.activeflag === '1' ?
                        <Modal
                            show={this.state.showHide2}
                            centered
                            onHide={() => this.handleModal1ShowHide()}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton centered="true">
                                <Modal.Title>Confirm</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <label htmlFor="" className="col-form-label mr-2 pt-1">
                                    Are you sure you want to Deactivate?
                                </label>
                            </Modal.Body>
                            <Modal.Footer>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => this.removeData()}
                                >
                                    &nbsp; Yes &nbsp;
                                </button>
                                <button
                                    className="btn btn-link"
                                    onClick={() => this.handleModal1ShowHide()}
                                >
                                    No
                                </button>
                            </Modal.Footer>
                        </Modal>
                        :
                        <Modal
                            show={this.state.showHide}
                            centered
                            onHide={() => this.handleModalShowHide()}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton centered="true">
                                <Modal.Title>Confirm</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <label htmlFor="" className="col-form-label mr-2 pt-1">
                                    Are you sure you want to  Activate ?
                                </label>
                            </Modal.Body>
                            <Modal.Footer>
                                <button
                                    className="btn btn-primary"
                                    id='activateDeactivateYesButton2'
                                    onClick={() => this.removeData1()}
                                >
                                    &nbsp; Yes &nbsp;
                                </button>
                                <button
                                    className="btn btn-link"
                                    id='activateDeactivateNoButton2'
                                    onClick={() => this.handleModalShowHide()}
                                >
                                    No
                                </button>
                            </Modal.Footer>
                        </Modal>
                    }
                    <div className="col-md-12">
                        <div className="panel-bg  mt-2">
                            {" "}

                            <div className="panelTitle p-0 d-flex">
                                <div className="col-6 my-auto">
                                    <strong>Language</strong>
                                </div>
                            </div>
                            <div className="p-3 mb70" style={{ marginBottom: "180px" }}>
                                {/* <!--  Table Panel Start --> */}
                                <ToolkitProvider
                                    keyField="ReportId"
                                    data={this.state.data.sort(this.sortIt('isactive'))}
                                    columns={this.columns}
                                >
                                    {(props) => (
                                        <div>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                pagination={paginationFactory({ ...options })}
                                                noDataIndication={() => <NoDataIndication />}
                                            />
                                        </div>
                                    )}
                                </ToolkitProvider>
                                {/* table panel ends */}
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        );
    }
}
