import { runWithAdal } from 'react-adal';
import { authContext } from './adalConfig';
import './index.css';
import "./assests/css/styles.css"
import 'font-awesome/css/font-awesome.min.css';
import './assests/css/bootstrap.min.css';
import './assests/css/dataTables.bootstrap4.min.css';
// Custom styles for this template
import "./assests/css/main_styles.css";
import * as config from "./config"


let reqPath = window.location.pathname;
let needAuth = (reqPath !== '/report' && reqPath !== '/login.html')
let validTenant = config.tenant && config.tenant.length > 34;
let validClientId = config.clientId && config.clientId.length > 34
runWithAdal(authContext, () => {

  // eslint-disable-next-line
  require('./auth.js');

}, !(validTenant && validClientId && needAuth));

