import React, { Component } from "react";

export class GigyaLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAdminUser: false
    };
  }

  render() {

    return (
      <>
        <script type="text/javascript" src="" />

        <div id="elanco-embed"></div>
        <div id="divresponse" style={{ display: "none" }}></div>
      </>
    );
  }

}
export default GigyaLogin;

