//import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from './components/HeaderComponent/header';
import PowerBiReport from './components/Report/powerbireport';
import Footer from './components/FooterComponent/footer';
import NotAuthorized from './components/NotAuthorizedComponent/notauthorized';
import Main from './components/LandingComponent/main';
import ViewPosting from './components/LandingComponent/viewposting';
import GigyaLogin from './components/LandingComponent/gigyaLogin'

import 'bootstrap/dist/css/bootstrap.css';
import "jquery/dist/jquery.min.js";
//import Posting from './components/LandingComponent/posting';
//import UsageReport from './components/LandingComponent/usageReport';
//import DetailedReportRequests from './components/LandingComponent/detailedReportRequests';

function App() {

  var nonHeaderFooterYN = window.location.pathname === '/report' || window.location.pathname === '/login.html';
  return (
    <div >{nonHeaderFooterYN ? null : <Header />}
      <Router>
        <div>
          <Switch>
            <Route exact path="/">
              <Main></Main>
            </Route>
            {/* <Route exact path="/Posting" >
                <Posting></Posting>
              </Route>
              <Route exact path="/UsageReport" >
                <UsageReport></UsageReport>
              </Route>
              <Route exact path="/DetailedReportRequests" >
                <DetailedReportRequests></DetailedReportRequests>
              </Route> */}
            <Route exact path="/ViewPosting" >
              <ViewPosting></ViewPosting>
            </Route>
            <Route exact path="/report">
              <PowerBiReport></PowerBiReport>
            </Route>
            <Route exact path="/login.html" >
              <GigyaLogin />
            </Route>
            <Route path="*" >
              <NotAuthorized />
            </Route>
          </Switch>
        </div>
      </Router>
      {nonHeaderFooterYN ? null : <Footer />}
      <div className="overlay" style={{ display: 'none' }} id='div-overlay'></div>
    </div>

  );
}

export default App;
