import React from "react";
import * as pbi from 'powerbi-client';
import { models } from "powerbi-client";
import userservice from '../../services/userService.js';


export default class powerBiReport extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      accessToken: '',
      reportId: '',
      height: (window.innerHeight + 'px'),
      powerheight: ((window.innerHeight - 10) + 'px'),
      url: ""
    };
    let irn = this.getQueryVariable('irn');
    let langulage = this.getQueryVariable('language');
    langulage = langulage === false ? 'english' : langulage;
    userservice.getemdedreportURL(irn, langulage)
      .then((response) => {

        this.setState({ url: response.data.embedUrl });
        this.setState({ reportId: response.data.reportId });
        this.setState({ accessToken: response.data.accessToken });
        this.showReport(response);
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  }

  // disaplay report
  showReport(response) {
    let config = {
      type: 'report',
      tokenType: models.TokenType.Embed,
      accessToken: response.data.accessToken,
      embedUrl: response.data.embedUrl,
      id: response.data.reportId,
      settings: {
        filterPaneEnabled: false,
        layoutType: 2
      }
    };

    let reportContainer = document.getElementById('dvreport');
    let powerbi1 = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    let report = powerbi1.embed(reportContainer, config);

    report.off("loaded");
    report.on("loaded", () => {

    });
    report.on("error", (ex) => {
    });
  }

  // get particular posting session irn
  getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === variable) { return pair[1]; }
    }
    return (false);
  }

  render() {

    return (
      <div style={{ height: this.state.height }} >
        <div id="dvreport" style={{ height: this.state.powerheight }} >

        </div>
      </div>
    );
  }
}
